import { sendToast } from "../../utils/toaster";
import {
    GET_SELECT_REVIEWS,
    GET_USER_DETAILS,
    GET_SUBSCRIPTION_INVOICES_SUCCESS,
    GET_SUBSCRIPTION_INVOICES,
    DELETE_USER,
    DEACTIVATE_USER,
    CHANGE_PERMISSION,
    GET_USER_DETAILS_SUCCESS,
    SET_CURRENT_USER_ID,
    UPDATE_QUESTION_FIELD,
    CHANGE_NEW_TEST_NAME_STATUS,
    CREATE_NEW_TEST,
    CHANGE_CURRENT_MODE,
    EDIT_ROLES,
    GET_SELECT_REVIEWS_SUCCESS,
    EDIT_API_KEYS,
    UPLOAD_INTERVIEW_DATA,
    GET_INTERVIEWS_DATA,
    GET_INTERVIEW_QUESTIONS,
    GET_INTERVIEW_QUESTIONS_SUCCESS,
    CREATE_INTERVIEW_QUESTION,
    ARCHIVE_INTERVIEW_QUESTION,
    INTERVIEWS_DATA_SUCCESS,
    ADD_COMPANY,
    UPLOAD_INTERVIEW_DATA_RESULT,
    UPDATE_TEST_BUILDER_QUESTIONS,
    UPDATE_TEST_BUILDER_TEST,
    PUBLISH_UPDATE_TEST,
    GET_QUESTIONS_DATA,
    GET_QUESTIONS_SUCCESS,
    ERROR_AUDIT_LOGS,
    UPDATE_CARD,
    RESET_TEST,
    EDIT_USERS,
    EDIT_TEAM_MEMBER,
    UPDATE_TEST_NAME,
    UPDATE_TEST_EMAIL_TEMPLATES,
    GET_AUDIT_LOGS,
    GET_INTERVIEWS_AND_QUESTIONS_DATA,
    GET_SCORECARD_DATA,
    GET_SCORECARD_DATA_SUCCESS,
    UPDATE_CARD_STATUS,
    SWITCH_PLANS,
    PRE_SET_ASSESSMENTS_SUCCESS,
    SEND_DISCORD_MESSAGE,
    SEND_REJECTION_EMAIL,
    SEND_SHORTLIST_EMAIL,
    RESEND_CANDIDATE_INVITE_EMAIL,
    SEND_REMINDER_EMAIL,
    UPDATE_TEST_SUBSCRIBERS,
    UPDATE_TEST_SUBSCRIBERS_LOADER,
    UPDATE_TEST_METADATA_LOADER,
    UPDATE_TEST_METADATA,
    COMPILE_CODE,
    UPDATE_USER_ACTIVITY,
    UPDATE_TEST_PROCTORING_MODE,
    UPDATE_TEST_ONELINK_MODE,
    GENERATE_TEST_ONELINK,
    TOGGLE_TEST_ONELINK,
    UPDATE_TEST_TIMEBOXED,
    UPDATE_TEST_THRESHOLD,
    COMPILE_CODE_SUCCESS,
    REVIEW_INTERVIEW,
    REMOVE_QUESTION_FROM_SCORECARD,
    END_INTERVIEW,
    DELETE_INTERVIEW,
    CANCEL_INTERVIEW,
    GET_TEST_LIBRARY,
    GET_TEST_LIBRARY_SUCCESS,
    SEARCH_TEST_LIBRARY,
    SEARCH_TEST_LIBRARY_SUCCESS,
    RESEND_TEAM_INVITE,
    ADD_NEW_TEST_REQUEST,
    ADD_UPDATE_EMAIL_TEMPLATE,
    GET_INSIGHTS,
    GET_INSIGHTS_SUCCESS,
    ADD_API_KEY,
    ADD_INTEGRATION_INFO,
    ADD_SLACK_INFO,
    TEST_SUCCESS_FACTORS,
    GET_AUDIT_LOGS_SUCCESS,
    ADD_MULTI_AUTH_DETAIL,
    REMOVE_SAMPLE_CANDIDATES,
    LOGOUT_USER,
    GET_PAIRS_PAGINATED,
    GET_PAIRS_PAGINATED_SUCCESS,
    CREATE_PAIR,
    REGISTER_CANDIDATE_PAIR_DETAILS,
    SEND_PAIR_INVITE_EMAIL,
    SEND_BULK_INVITES,
    SEND_BULK_INVITES_SUCCESS,
    CREATE_READY_TEST,
    GET_PUBLIC_QUESTIONS,
    GET_PUBLIC_QUESTIONS_SUCCESS,
    GET_READY_ASSESSMENT_SUCCESS,
    FETCH_ONBOARDING_DATA,
    FETCH_ONBOARDING_DATA_SUCCESS,
    EXPORT_GRAPH_FETCH,
    EXPORT_GRAPH_FETCH_SUCCESS,
    ADD_QUESTIONS_TO_TEST,
    ADD_QUESTIONS_TO_TEST_RESULT,
    DELETE_QUESTION_TEST,
    DELETE_QUESTION_TEST_RESULT,
    FETCH_RTG_DATA_SUCCESS,
    FETCH_RTG_DATA,
    GET_TEST_METADATA,
    GET_TEST_METADATA_SUCCESS,
    SORT_QUESTIONS_DND,
    SORT_QUESTIONS_DND_RESULT,
    DELETE_CARD_DETAILS,
    DELETE_CARD_DETAILS_SUCCESS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_SUCCESS,
    FETCH_SAAS_PLANS,
    FETCH_SAAS_PLANS_SUCCESS,
    DELETE_TEST,
    DELETE_TEST_STATUS,
    GET_SCORECARD_IP_DETAILS,
    GET_SCORECARD_IP_DETAILS_SUCCESS,
    GET_SCORECARD_INTERVIEW_DATA,
    GET_SCORECARD_INTERVIEW_DATA_SUCCESS,
    GET_CANDIDATE_FUNNEL,
    GET_CANDIDATE_FUNNEL_SUCCESS,
    GET_SCORE_DISTRIBUTION,
    GET_SCORE_DISTRIBUTION_SUCCESS,
    GET_DAYS_DATA,
    GET_DAYS_DATA_SUCCESS,
    GET_INVITED_BY,
    GET_INVITED_BY_SUCCESS,
    GET_AVG_COMPLETION_TIME,
    GET_AVG_COMPLETION_TIME_SUCCESS,
    GET_AVG_RATINGS,
    GET_AVG_RATINGS_SUCCESS,
    GET_REVIEWS,
    GET_REVIEWS_SUCCESS,
    GET_SHALLOW_INTERVIEWS,
    GET_SHALLOW_INTERVIEWS_SUCCESS,
    DELETE_INTERVIEW_SUCCESS,
    GET_HIGH_LEVEL_INTERVIEWS_TOTAL,
    GET_HIGH_LEVEL_INTERVIEWS_TOTAL_SUCCESS,
    GET_INTERVIEW_DETAILS,
    GET_INTERVIEW_DETAILS_SUCCESS,
    CANCEL_INTERVIEW_SUCCESS,
    END_INTERVIEW_SUCCESS,
    PREVIEW_ASSESSMENT,
    PREVIEW_ASSESSMENT_SUCCESS,
    GET_PREVIEW_TEST,
    GET_PREVIEW_TEST_SUCCESS,
    DELETE_PREVIEW_QUESTION_TEST,
    ADD_NEW_TEST_REQUEST_SUCCESS,
    GET_SCORE_TIME_SERIES,
    GET_SCORE_TIME_SERIES_SUCCESS,
    GENERATE_TEST_ONELINK_SUCCESS,
    FETCH_ADDRESS_SUCCESS,
    FETCH_ADDRESS,
    FETCH_LIVE_UPDATES,
    FETCH_LIVE_UPDATES_SUCCESS,
    FETCH_PROBLEMS,
    FETCH_PROBLEMS_SUCCESS,
    SAVE_PROBLEM_SUCCESS,
    SAVE_PROBLEM_FAILURE,
    SAVE_PROBLEM,
    SET_EDIT_PROBLEM,
    CLEAR_SAVED_PROBLEM,
    FETCH_SUBMISSIONS,
    FETCH_SUBMISSIONS_SUCCESS,
    UPDATE_CREDITS_MANUALLY,
    UPDATE_CREDITS_MANUALLY_STATUS,
    UPDATE_QUESTION_FIELD_STATUS,
    REVIEW_BULK_INTERVIEWS,
    REVIEW_BULK_INTERVIEWS_STATUS,
    CANCEL_BULK_INTERVIEWS,
    CANCEL_BULK_INTERVIEWS_STATUS,
    SEND_BULK_RESULT_EMAIL_TO_CANDIDATE,
    SEND_BULK_RESULT_EMAIL_TO_CANDIDATE_STATUS,
    SET_EDIT_CUSTOM_QUESTION,
    CUSTOMIZE_QUESTIONS_TO_TEST_RESULT,
    VERIFY_QUESTION,
    VERIFY_QUESTION_SUCCESS,
    FETCH_NOTIFICATION_FEED,
    FETCH_NOTIFICATION_FEED_STATUS,
    TRIGGER_KNOCK_NOTIFICATION,
    GET_QUESTIONS_DATA_TEST,
    GET_QUESTIONS_DATA_TEST_SUCCESS,
    GET_ADDITIONAL_TESTS_DATA,
    GET_ADDITIONAL_TESTS_DATA_SUCCESS,
    UPDATE_FAQS,
    FETCH_ADA_AI_CONTACTS,
    FETCH_ADA_AI_CONTACTS_STATUS,
    FETCH_ADA_AI_CONTACT_DOCUMENTS,
    FETCH_ADA_AI_CONTACT_DOCUMENTS_STATUS,
    FETCH_ADA_AI_CONTACT_SESSIONS,
    FETCH_ADA_AI_CONTACT_SESSIONS_STATUS,
    CREATE_ADA_AI_QUERY,
    CREATE_ADA_AI_QUERY_STATUS,
    UPSERT_DOCUMENTS,
    UPSERT_DOCUMENTS_STATUS,
    UPDATE_FAQS_STATUS,
    REVIEW_INTERVIEW_SUCCESS,
    UPLOAD_INTERVIEW_DATA_SUCCESS,
    ADD_OR_UPDATE_LISTMONK,
    GET_ALL_CATEGORIES,
    GET_ALL_CATEGORIES_SUCCESS,
    COMPLETELY_DELETE_USER,
    UPDATE_BILLING_DETAILS,
    UPDATE_BILLING_DETAILS_SUCCESS,
    UPDATE_BILLING_DETAILS_ERROR,
    GET_BLOCKED_EMAILS,
    DELETE_BLOCKED_EMAIL,
    GET_BLOCKED_EMAILS_SUCCESS,
    DELETE_BLOCKED_EMAIL_SUCCESS,
    SEND_CUSTOM_TEST_READY_EMAIL,
    EXTEND_INTERVIEW_DEADLINE,
    EXTEND_INTERVIEW_DEADLINE_SUCCESS,
    EXTEND_INTERVIEW_DEADLINE_FAILURE,
    CLONE_TEST,
    CLONE_TEST_STATUS,
    CREATE_BLANK_TEST,
    CREATE_BLANK_TEST_STATUS,
    EDIT_EMAIL_TEMPLATE_STATUS,
    EDIT_EMAIL_TEMPLATE,
} from "./constants";

export function deleteCardDetails({ env, companyId }) {
    return {
        type: DELETE_CARD_DETAILS,
        env,
        companyId,
    };
}

export function deleteCardDetailsSuccess({ companyId, result }) {
    return {
        type: DELETE_CARD_DETAILS_SUCCESS,
        companyId,
        result,
    };
}

export function updateBillingDetails({ companyId, env, billingDetails }) {
    return {
        type: UPDATE_BILLING_DETAILS,
        companyId,
        env,
        billingDetails,
    };
}

export function updateBillingDetailsSuccess({
    companyId,
    result,
    updatedBillingDetails,
}) {
    return {
        type: UPDATE_BILLING_DETAILS_SUCCESS,
        companyId,
        result,
        updatedBillingDetails,
    };
}

export function updateBillingDetailsError() {
    return {
        type: UPDATE_BILLING_DETAILS_ERROR,
    };
}

export function cancelSubscription({
    env,
    companyId,
    planId,
    reasons,
    suggestions,
}) {
    return {
        type: CANCEL_SUBSCRIPTION,
        env,
        companyId,
        planId,
        reasons,
        suggestions,
    };
}

export function cancelSubscriptionSuccess({ companyId, result }) {
    return {
        type: CANCEL_SUBSCRIPTION_SUCCESS,
        companyId,
        result,
    };
}

export function exportGraphFetch({
    companyId,
    testId,
    startDate,
    endDate,
    timeOffsetInSeconds,
}) {
    return {
        type: EXPORT_GRAPH_FETCH,
        companyId,
        testId,
        startDate,
        endDate,
        timeOffsetInSeconds,
    };
}

export function exportGraphFetchSuccess({ data }) {
    return {
        type: EXPORT_GRAPH_FETCH_SUCCESS,
        data,
    };
}

export function fetchOnboardingData({ emailId }) {
    return {
        emailId,
        type: FETCH_ONBOARDING_DATA,
    };
}

export function fetchOnboardingDataSuccess({ data }) {
    return {
        type: FETCH_ONBOARDING_DATA_SUCCESS,
        data,
    };
}

export function getReadyAssessmentSuccess({ testId, questions }) {
    return {
        type: GET_READY_ASSESSMENT_SUCCESS,
        testId,
        questions,
    };
}

export function getPublicQuestionsSuccess({
    categories,
    testId,
    questions,
    allSkills,
    allCategories,
}) {
    return {
        type: GET_PUBLIC_QUESTIONS_SUCCESS,
        categories,
        testId,
        questions,
        allSkills,
        allCategories,
    };
}

export function getPublicQuestions({ categories, testId }) {
    return {
        type: GET_PUBLIC_QUESTIONS,
        testId,
        categories,
    };
}

export function getTestMetadata({ testId }) {
    return {
        type: GET_TEST_METADATA,
        testId,
    };
}

export function getTestMetadataSuccess({ testId, data }) {
    return {
        type: GET_TEST_METADATA_SUCCESS,
        data,
        testId,
    };
}

export function logoutUser({ emailId, companyId }) {
    return {
        type: LOGOUT_USER,
        emailId,
        companyId,
    };
}

export function addMultiAuthDetail({ authData }) {
    return {
        type: ADD_MULTI_AUTH_DETAIL,
        authData,
    };
}

export function errorAuditLogs({
    companyId,
    env,
    description,
    metadata,
    event,
}) {
    return {
        type: ERROR_AUDIT_LOGS,
        companyId,
        env,
        description,
        metadata,
        event,
    };
}

export function addApiKey({ apiKey, companyId, env }) {
    return {
        type: ADD_API_KEY,
        apiKey,
        companyId,
        env,
    };
}

export function addSlackInfo({
    companyId,
    deleteSlackWebhook,
    slackNotificationsInvite,
    slackNotificationsCandidateScores,
    env,
}) {
    return {
        type: ADD_SLACK_INFO,
        companyId,
        env,
        deleteSlackWebhook,
        slackNotificationsInvite,
        slackNotificationsCandidateScores,
    };
}

export function addIntegrationInfo({
    integration,
    companyId,
    SFpassword,
    SFusername,
    SFcompanyId,
    env,
    SFdomain,
    leverAuthCode,
    ashbyAPIKey,
    enableAshbyIntegration,
    SRCompanyId,
    enableSRIntegration,
}) {
    return {
        type: ADD_INTEGRATION_INFO,
        integration,
        SFusername,
        SFpassword,
        SFdomain,
        companyId,
        SFcompanyId,
        leverAuthCode,
        ashbyAPIKey,
        enableAshbyIntegration,
        env,
        SRCompanyId,
        enableSRIntegration,
    };
}

export function testSuccessFactors({
    companyId,
    SFpassword,
    SFusername,
    SFcompanyId,
    SFdomain,
}) {
    return {
        type: TEST_SUCCESS_FACTORS,
        companyId,
        SFpassword,
        SFusername,
        SFcompanyId,
        SFdomain,
    };
}

export function getInsights({
    companyId,
    testId,
    includeAllRatings,
    startDate,
    endDate,
}) {
    return {
        type: GET_INSIGHTS,
        companyId,
        testId,
        includeAllRatings,
        startDate,
        endDate,
    };
}

export function getInsightsSuccess({ data, testId }) {
    return {
        type: GET_INSIGHTS_SUCCESS,
        data,
        testId,
    };
}

export function getCandidateFunnel({ companyId, testId, startDate, endDate }) {
    return {
        type: GET_CANDIDATE_FUNNEL,
        companyId,
        testId,
        startDate,
        endDate,
    };
}

export function getCandidateFunnelSuccess({ data, testId }) {
    return {
        type: GET_CANDIDATE_FUNNEL_SUCCESS,
        data,
        testId,
    };
}

export function getScoreDistribution({
    companyId,
    testId,
    startDate,
    endDate,
}) {
    return {
        type: GET_SCORE_DISTRIBUTION,
        companyId,
        testId,
        startDate,
        endDate,
    };
}

export function getScoreDistributionSuccess({ data, testId }) {
    return {
        type: GET_SCORE_DISTRIBUTION_SUCCESS,
        data,
        testId,
    };
}

export function getDaysData({ companyId, testId, startDate, endDate }) {
    return {
        type: GET_DAYS_DATA,
        companyId,
        testId,
        startDate,
        endDate,
    };
}

export function getDaysDataSuccess({ data, testId }) {
    return {
        type: GET_DAYS_DATA_SUCCESS,
        data,
        testId,
    };
}

export function getInvitedBy({ companyId, testId, startDate, endDate }) {
    return {
        type: GET_INVITED_BY,
        companyId,
        testId,
        startDate,
        endDate,
    };
}

export function getInvitedBySuccess({ data, testId }) {
    return {
        type: GET_INVITED_BY_SUCCESS,
        data,
        testId,
    };
}

export function getAvgCompletionTime({
    companyId,
    testId,
    startDate,
    endDate,
}) {
    return {
        type: GET_AVG_COMPLETION_TIME,
        companyId,
        testId,
        startDate,
        endDate,
    };
}

export function getAvgCompletionTimeSuccess({ data, testId }) {
    return {
        type: GET_AVG_COMPLETION_TIME_SUCCESS,
        data,
        testId,
    };
}

export function getAvgRatings({ companyId, testId, startDate, endDate }) {
    return {
        type: GET_AVG_RATINGS,
        companyId,
        testId,
        startDate,
        endDate,
    };
}

export function getAvgRatingsSuccess({ data, testId }) {
    return {
        type: GET_AVG_RATINGS_SUCCESS,
        data,
        testId,
    };
}

export function getReviews({ companyId, testId, startDate, endDate }) {
    return {
        type: GET_REVIEWS,
        companyId,
        testId,
        startDate,
        endDate,
    };
}

export function getReviewsSuccess({ data, testId }) {
    return {
        type: GET_REVIEWS_SUCCESS,
        data,
        testId,
    };
}

export function getScoreTimeSeries({ companyId, testId, startDate, endDate }) {
    return {
        type: GET_SCORE_TIME_SERIES,
        companyId,
        testId,
        startDate,
        endDate,
    };
}

export function getScoreTimeSeriesSuccess({ data, testId }) {
    return {
        type: GET_SCORE_TIME_SERIES_SUCCESS,
        data,
        testId,
    };
}

export function addNewTestRequest({
    env,
    companyId,
    testUrl,
    testName,
    testLanguage,
    jd,
    comments,
    experience,
    requesterName,
    algorithms,
    coding,
    skills,
    salary,
    requesterEmailId,
}) {
    return {
        type: ADD_NEW_TEST_REQUEST,
        env,
        companyId,
        testUrl,
        testName,
        testLanguage,
        requesterName,
        requesterEmailId,
        jd,
        comments,
        experience,
        algorithms,
        coding,
        skills,
        salary,
    };
}

export function addNewTestRequestSuccess({ status, testUrl }) {
    return {
        type: ADD_NEW_TEST_REQUEST_SUCCESS,
        status,
        testUrl,
    };
}

export function getTestLibrary({ env, categoryId }) {
    return {
        type: GET_TEST_LIBRARY,
        categoryId,
        env,
    };
}

export function getTestLibrarySuccess({
    categories,
    tests,
    categoryId,
    testsMap,
}) {
    return {
        type: GET_TEST_LIBRARY_SUCCESS,
        categories,
        tests,
        categoryId,
        testsMap,
    };
}

export function getAdditionalTestsData({ companyId }) {
    return {
        type: GET_ADDITIONAL_TESTS_DATA,
        companyId,
    };
}

export function getAdditionalTestsDataSuccess({ additionalTests }) {
    return {
        type: GET_ADDITIONAL_TESTS_DATA_SUCCESS,
        additionalTests,
    };
}

export function searchTestLibrary({ keyword, env, pageNo, userData }) {
    return {
        type: SEARCH_TEST_LIBRARY,
        keyword,
        env,
        pageNo,
        userData,
    };
}

export function searchTestLibrarySuccess({ keyword, tests, readyTests, env }) {
    return {
        type: SEARCH_TEST_LIBRARY_SUCCESS,
        keyword,
        tests,
        readyTests,
    };
}

export function deleteInterview({
    env,
    companyId,
    interviewId,
    deleteAll,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    view = "table",
    fromColumn,
}) {
    return {
        type: DELETE_INTERVIEW,
        env,
        companyId,
        interviewId,
        deleteAll,
        limit,
        pageNo,
        filters,
        sortBy,
        view,
        fromColumn,
    };
}

export function deleteInterviewSuccess({
    status,
    interviewId,
    view,
    fromColumn,
}) {
    return {
        type: DELETE_INTERVIEW_SUCCESS,
        status,
        interviewId,
        view,
        fromColumn,
    };
}

export function removeSampleCandidates({
    env,
    view,
    companyId,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
}) {
    return {
        type: REMOVE_SAMPLE_CANDIDATES,
        limit,
        pageNo,
        filters,
        sortBy,
        env,
        companyId,
        view,
    };
}

export function getSelectReviews({ env, companyId }) {
    return {
        type: GET_SELECT_REVIEWS,
        env,
        companyId,
    };
}

export function getSelectReviewsSuccess({ reviews, status, oneShotData }) {
    return {
        type: GET_SELECT_REVIEWS_SUCCESS,
        reviews,
        status,
        oneShotData,
    };
}

export function removeQuestionFromScorecard({
    env,
    questionId,
    interviewId,
    companyId,
}) {
    return {
        type: REMOVE_QUESTION_FROM_SCORECARD,
        companyId,
        env,
        questionId,
        interviewId,
    };
}

export function reviewInterview({
    view = "table",
    env,
    companyId,
    interviewId,
    shortlisted,
    archived,
    limit = 50,
    pageNo = 1,
    filters = [],
    sortBy = "none",
    action,
    emailId,
    candidateEmailId,
    percentage,
    fromColumn,
    toColumn,
}) {
    return {
        view,
        type: REVIEW_INTERVIEW,
        shortlisted,
        companyId,
        env,
        interviewId,
        archived,
        pageNo,
        filters,
        sortBy,
        limit,
        action,
        emailId,
        candidateEmailId,
        percentage,
        fromColumn,
        toColumn,
    };
}

export function reviewInterviewSuccess({
    status,
    interviewId,
    view,
    action,
    shortlisted,
    archived,
    fromColumn,
    toColumn,
}) {
    return {
        type: REVIEW_INTERVIEW_SUCCESS,
        status,
        interviewId,
        view,
        action,
        shortlisted,
        archived,
        fromColumn,
        toColumn,
    };
}

export function reviewBulkInterviews({
    env,
    companyId,
    reviewInterviews,
    limit = 25,
    pageNo = 1,
    filters = [],
    sortBy = "none",
    action,
    emailId,
}) {
    return {
        type: REVIEW_BULK_INTERVIEWS,
        companyId,
        env,
        reviewInterviews,
        pageNo,
        filters,
        sortBy,
        limit,
        action,
        emailId,
    };
}

export function reviewBulkInterviewsStatus({
    status,
    action: interviewAction,
}) {
    return {
        type: REVIEW_BULK_INTERVIEWS_STATUS,
        status,
        interviewAction,
    };
}

export function endInterview({ env, companyId, interviewId, end, by }) {
    return {
        type: END_INTERVIEW,
        companyId,
        env,
        interviewId,
        end,
        by,
    };
}

export function endInterviewSuccess({ status, interviewId }) {
    return {
        type: END_INTERVIEW_SUCCESS,
        status,
        interviewId,
    };
}

export function cancelInterview({
    view,
    env,
    companyId,
    interviewId,
    admin,
    emailId,
}) {
    return {
        type: CANCEL_INTERVIEW,
        view,
        companyId,
        env,
        interviewId,
        admin,
        emailId,
    };
}

export function cancelInterviewSuccess({ status, interviewId, view }) {
    return {
        type: CANCEL_INTERVIEW_SUCCESS,
        view,
        status,
        interviewId,
    };
}

export function cancelBulkInterviews({
    env,
    companyId,
    interviewIds,
    admin,
    emailId,
}) {
    return {
        type: CANCEL_BULK_INTERVIEWS,
        companyId,
        env,
        interviewIds,
        admin,
        emailId,
    };
}

export function cancelBulkInterviewsStatus({ status, interviewIds }) {
    return {
        type: CANCEL_BULK_INTERVIEWS_STATUS,
        status,
        interviewIds,
    };
}
export function updateTestEmailTemplates({
    env,
    companyId,
    testId,
    templateName,
    templateKey,
}) {
    return {
        type: UPDATE_TEST_EMAIL_TEMPLATES,
        companyId,
        env,
        testId,
        templateName,
        templateKey,
    };
}

export function updateTestTimeboxed({ env, companyId, testId, timeboxed }) {
    return {
        type: UPDATE_TEST_TIMEBOXED,
        companyId,
        testId,
        timeboxed,
        env,
    };
}

export function generateTestOnelink({ env, companyId, testId }) {
    return {
        type: GENERATE_TEST_ONELINK,
        env,
        companyId,
        testId,
    };
}

export function generateTestOnelinkSuccess({ status }) {
    return {
        type: GENERATE_TEST_ONELINK_SUCCESS,
        status,
    };
}

export function toggleTestOnelink({
    env,
    companyId,
    testId,
    onelink,
    turnoff,
}) {
    return {
        type: TOGGLE_TEST_ONELINK,
        env,
        companyId,
        testId,
        onelink,
        turnoff,
    };
}

export function updateTestProctoringMode({
    env,
    companyId,
    testId,
    proctoringMode,
    videoProctoringMode,
    randomizeQuestions,
    requireFullscreen,
    requireScreenSharing,
}) {
    return {
        type: UPDATE_TEST_PROCTORING_MODE,
        companyId,
        testId,
        proctoringMode,
        videoProctoringMode,
        env,
        randomizeQuestions,
        requireFullscreen,
        requireScreenSharing,
    };
}

export function updateTestThreshold({ env, companyId, testId, threshold }) {
    return {
        type: UPDATE_TEST_THRESHOLD,
        companyId,
        testId,
        threshold,
        env,
    };
}

export function updateUserActivity({ emailId, env, companyId }) {
    return {
        type: UPDATE_USER_ACTIVITY,
        emailId,
        companyId,
        env,
    };
}

export function compileCode({
    index,
    mode,
    code,
    stdin,
    modeVersion,
    filename,
    interviewId,
}) {
    return {
        type: COMPILE_CODE,
        index,
        mode,
        code,
        stdin,
        modeVersion,
        filename,
        interviewId,
    };
}

export function compileCodeSuccess({
    interviewId,
    index,
    glotstdout,
    glotstderr,
    gloterror,
}) {
    return {
        type: COMPILE_CODE_SUCCESS,
        interviewId,
        index,
        glotstdout,
        glotstderr,
        gloterror,
    };
}

export function updateTestSubscribersLoader({ show }) {
    return {
        type: UPDATE_TEST_SUBSCRIBERS_LOADER,
        show,
    };
}

export function updateTestMetadataLoader({ show }) {
    return {
        type: UPDATE_TEST_METADATA_LOADER,
        show,
    };
}

export function sendRejectionEmail({
    env,
    interviewId,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
    emailId,
    candidateEmailId,
    percentage,
    replyTo,
}) {
    return {
        type: SEND_REJECTION_EMAIL,
        companyId,
        env,
        interviewId,
        limit,
        pageNo,
        filters,
        sortBy,
        emailId,
        candidateEmailId,
        percentage,
        replyTo,
    };
}

export function updateTestMetadata({ testId, metadata, companyId, reason }) {
    return {
        type: UPDATE_TEST_METADATA,
        testId,
        metadata,
        companyId,
        reason,
    };
}

export function resendCandidateInviteEmail({ interviewId }) {
    return {
        type: RESEND_CANDIDATE_INVITE_EMAIL,
        interviewId,
    };
}

export function sendShortlistEmail({
    env,
    interviewId,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
    emailId,
    candidateEmailId,
    percentage,
    replyTo,
}) {
    return {
        type: SEND_SHORTLIST_EMAIL,
        companyId,
        env,
        interviewId,
        limit,
        pageNo,
        filters,
        sortBy,
        emailId,
        candidateEmailId,
        percentage,
        replyTo,
    };
}

export function sendBulkResultEmailToCandidate({
    env,
    interviewIds,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
    action,
    notificationDetails,
}) {
    return {
        type: SEND_BULK_RESULT_EMAIL_TO_CANDIDATE,
        companyId,
        env,
        interviewIds,
        limit,
        pageNo,
        filters,
        sortBy,
        action,
        notificationDetails,
    };
}

export function sendBulkResultEmailToCandidateStatus({
    env,
    interviewIds,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
    action,
}) {
    return {
        type: SEND_BULK_RESULT_EMAIL_TO_CANDIDATE_STATUS,
        companyId,
        env,
        interviewIds,
        limit,
        pageNo,
        filters,
        sortBy,
        action,
    };
}

export function sendBulkInvites({
    env,
    companyId,
    emails,
    names,
    testId,
    testName,
    requesterName,
    requesterEmailId,
    questionsSummary,
    interviewScheduledTime,
    interviewExpiryTime,
    recruiterTimeZone,
    campaign,
    replyTo,
    view = "table",
}) {
    return {
        type: SEND_BULK_INVITES,
        env,
        companyId,
        emails,
        names,
        testId,
        testName,
        requesterName,
        requesterEmailId,
        questionsSummary,
        interviewScheduledTime,
        interviewExpiryTime,
        recruiterTimeZone,
        campaign,
        replyTo,
        view,
    };
}

export function sendBulkInvitesSuccess({ success, data, view }) {
    return {
        type: SEND_BULK_INVITES_SUCCESS,
        success,
        data,
        view,
    };
}

export function sendPairInviteEmail({
    env,
    interviewId,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
    candidateName,
    candidateEmailId,
    candidatePhoneNumber,
    instructions,
}) {
    return {
        type: SEND_PAIR_INVITE_EMAIL,
        companyId,
        env,
        interviewId,
        limit,
        pageNo,
        filters,
        sortBy,
        candidateName,
        candidateEmailId,
        candidatePhoneNumber,
        instructions,
    };
}

export function registerCandidatePairDetails({
    env,
    interviewId,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    candidateName,
    candidatePhoneNumber,
    candidateEmailId,
    companyId,
    instructions,
}) {
    return {
        type: REGISTER_CANDIDATE_PAIR_DETAILS,
        env,
        interviewId,
        limit,
        pageNo,
        filters,
        sortBy,
        interviewId,
        candidateName,
        candidatePhoneNumber,
        candidateEmailId,
        companyId,
        instructions,
    };
}

export function sendReminderEmail({
    env,
    interviewId,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
    emailId,
    candidateEmailId,
    percentage,
}) {
    return {
        type: SEND_REMINDER_EMAIL,
        companyId,
        env,
        interviewId,
        limit,
        pageNo,
        filters,
        sortBy,
        emailId,
        candidateEmailId,
        percentage,
    };
}

export function updateTestName({ env, testId, companyId, name }) {
    return {
        type: UPDATE_TEST_NAME,
        testId,
        env,
        companyId,
        name,
    };
}

export function deleteTest({ env, testId, companyId, savedTests }) {
    return {
        type: DELETE_TEST,
        testId,
        env,
        companyId,
        savedTests,
    };
}

export function deleteTestStatus({ status }) {
    return {
        type: DELETE_TEST_STATUS,
        status,
    };
}

export function updateTestSubscribers({
    env,
    testId,
    companyId,
    newUsers,
    subscribers,
    invitingName,
    invitingEmailId,
}) {
    return {
        type: UPDATE_TEST_SUBSCRIBERS,
        testId,
        companyId,
        subscribers,
        newUsers,
        env,
        invitingName,
        invitingEmailId,
    };
}

export function sendDiscordMessage({ message, channel }) {
    return {
        type: SEND_DISCORD_MESSAGE,
        message,
        channel,
    };
}

export function triggerKnockNotification({ companyId, action, message, data }) {
    return {
        type: TRIGGER_KNOCK_NOTIFICATION,
        companyId,
        action,
        message,
        data,
    };
}

export function updateCardStatus({ status, message, subscription }) {
    return {
        type: UPDATE_CARD_STATUS,
        status,
        message,
        subscription,
    };
}

export function getSubscriptionInvoices({ companyId }) {
    return {
        type: GET_SUBSCRIPTION_INVOICES,
        companyId,
    };
}

export function getSubscriptionInvoicesSuccess({ subscription, invoices }) {
    return {
        type: GET_SUBSCRIPTION_INVOICES_SUCCESS,
        subscription,
        invoices,
    };
}

export function switchPlans({
    planId,
    planName,
    planCredits,
    companyId,
    env,
    token,
    emailId,
    name,
    address_line1,
    address_city,
    address_state,
    address_zip,
    address_country,
    recruiterName,
}) {
    return {
        type: SWITCH_PLANS,
        planId,
        planName,
        planCredits,
        companyId,
        env,
        token,
        emailId,
        name,
        address_line1,
        address_city,
        address_state,
        address_zip,
        address_country,
        recruiterName,
    };
}

export function updateCreditsManually({ operation, companyId }) {
    return { type: UPDATE_CREDITS_MANUALLY, operation, companyId };
}

export function updateCreditsManuallyStatus({ status, companyId, credit }) {
    return { type: UPDATE_CREDITS_MANUALLY_STATUS, status, companyId, credit };
}
export function updateCard({
    companyId,
    token,
    env,
    emailId,
    name,
    address_line1,
    address_city,
    address_country,
    address_zip,
}) {
    return {
        type: UPDATE_CARD,
        companyId,
        token,
        env,
        emailId,
        name,
        address_line1,
        address_city,
        address_country,
        address_zip,
    };
}

export function getScorecardData({ interviewId, env, companyId }) {
    return {
        type: GET_SCORECARD_DATA,
        companyId,
        interviewId,
        env,
    };
}

export function getScorecardDataSuccess({
    interviewId,
    companyId,
    interviewData,
}) {
    return {
        type: GET_SCORECARD_DATA_SUCCESS,
        interviewId,
        companyId,
        interviewData,
    };
}

export function getScorecardIpDetails({ interviewId, env, companyId }) {
    return {
        type: GET_SCORECARD_IP_DETAILS,
        companyId,
        interviewId,
        env,
    };
}

export function getScorecardIpDetailsSuccess({
    interviewId,
    companyId,
    ipProctoringDetails,
}) {
    return {
        type: GET_SCORECARD_IP_DETAILS_SUCCESS,
        interviewId,
        companyId,
        ipProctoringDetails,
    };
}

export function getScorecardInterviewData({ interviewId, env, companyId }) {
    return {
        type: GET_SCORECARD_INTERVIEW_DATA,
        companyId,
        interviewId,
        env,
    };
}

export function getScorecardInterviewDataSuccess({
    interviewId,
    companyId,
    interviewData,
}) {
    return {
        type: GET_SCORECARD_INTERVIEW_DATA_SUCCESS,
        interviewId,
        companyId,
        interviewData,
    };
}

export function addCompany({ name, id, env }) {
    return {
        type: ADD_COMPANY,
        name,
        id,
        env,
    };
}

export function getInterviewQuestions({
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    return {
        env,
        limit,
        pageNo,
        filters,
        sortBy,
        companyId,
        type: GET_INTERVIEW_QUESTIONS,
    };
}

export function getInterviewsData({
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    return {
        type: GET_INTERVIEWS_DATA,
        env,
        companyId,
        limit,
        pageNo,
        filters,
        sortBy,
    };
}

export function getPairsData({
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    return {
        type: GET_PAIRS_PAGINATED,
        limit,
        pageNo,
        filters,
        sortBy,
        companyId,
    };
}

export function getShallowInterviewsData({
    view = "table",
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    return {
        type: GET_SHALLOW_INTERVIEWS,
        view,
        env,
        companyId,
        limit,
        pageNo,
        filters,
        sortBy,
    };
}

export function getShallowInterviewsDataSuccess({
    companyId,
    interviews,
    accountDeactivated,
    filters,
    pageNo,
    view,
}) {
    return {
        type: GET_SHALLOW_INTERVIEWS_SUCCESS,
        companyId,
        interviews,
        accountDeactivated,
        view,
        filters,
        pageNo,
    };
}

export function getInterviewDetails({ interviewId, companyId }) {
    return {
        type: GET_INTERVIEW_DETAILS,
        interviewId,
        companyId,
    };
}

export function getInterviewDetailsSuccess({ interviewId, companyId, data }) {
    return {
        type: GET_INTERVIEW_DETAILS_SUCCESS,
        interviewId,
        companyId,
        data,
    };
}

export function getHighLevelInterviewsTotal({
    env,
    limit,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    return {
        type: GET_HIGH_LEVEL_INTERVIEWS_TOTAL,
        env,
        companyId,
        limit,
        filters,
        sortBy,
    };
}

export function getHighLevelInterviewsTotalSuccess({ companyId, total }) {
    return {
        type: GET_HIGH_LEVEL_INTERVIEWS_TOTAL_SUCCESS,
        total,
    };
}

export function getPairsDataSuccess({ companyId, interviews }) {
    return {
        type: GET_PAIRS_PAGINATED_SUCCESS,
        companyId,
        interviews,
    };
}

export function getInterviewQuestionsSucess({ companyId, questions }) {
    return {
        type: GET_INTERVIEW_QUESTIONS_SUCCESS,
        companyId,
        questions,
    };
}

export function createInterviewQuestion({
    companyId,
    addedBy,
    name,
    question,
    language,
    code,
    interviewerNotes,
    tags,
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
}) {
    return {
        type: CREATE_INTERVIEW_QUESTION,
        companyId,
        addedBy,
        name,
        question,
        language,
        code,
        interviewerNotes,
        tags,
        env,
        limit,
        pageNo,
        filters,
        sortBy,
    };
}

export function archiveInterviewQuestion({
    companyId,
    id,
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
}) {
    return {
        type: ARCHIVE_INTERVIEW_QUESTION,
        id,
        env,
        limit,
        pageNo,
        filters,
        sortBy,
        companyId,
    };
}

export function getAuditLogs({ env, limit, pageNo, companyId }) {
    return {
        type: GET_AUDIT_LOGS,
        companyId,
        pageNo,
        limit,
        env,
    };
}

export function getInterviewsAndQuestionsData({
    env,
    limit,
    pageNo,
    filters = {},
    sortBy = "none",
    companyId,
}) {
    return {
        type: GET_INTERVIEWS_AND_QUESTIONS_DATA,
        env,
        limit,
        pageNo,
        filters,
        sortBy,
        companyId,
    };
}

export function interviewsDataSuccess({ companyId, interviews }) {
    return {
        type: INTERVIEWS_DATA_SUCCESS,
        companyId,
        interviews,
    };
}

export function auditLogsSuccess({ companyId, data }) {
    return {
        type: GET_AUDIT_LOGS_SUCCESS,
        companyId,
        data,
    };
}

export function addOrUpdateEmail({
    templateName,
    subjectString,
    htmlString,
    subjectWithCandidateName,
    subjectWithoutCandidateName,
    htmlTemplate,
    companyId,
    env,
}) {
    return {
        templateName,
        subjectString,
        env,
        htmlString,
        subjectWithCandidateName,
        subjectWithoutCandidateName,
        htmlTemplate,
        companyId,
        type: ADD_UPDATE_EMAIL_TEMPLATE,
    };
}

export function editEmailTemplate({
    env,
    companyId,
    templateName,
    htmlTemplate,
    subjectWithoutCandidateName,
    subjectWithCandidateName,
    htmlString,
    subjectString,
    testId,
    emailType,
}) {
    return {
        type: EDIT_EMAIL_TEMPLATE,
        env,
        companyId,
        templateName,
        htmlTemplate,
        subjectWithoutCandidateName,
        subjectWithCandidateName,
        htmlString,
        subjectString,
        testId,
        emailType,
    };
}

export function editEmailTemplateStatus({ status }) {
    return {
        type: EDIT_EMAIL_TEMPLATE_STATUS,
        status,
    };
}

export function uploadInterviewDataResult({
    env,
    companyId,
    roleId,
    candidateEmailId,
    testId,
    testName,
    candidateName,
    candidatePhoneNumber,
    interviewId,
    informCandidate,
    limit,
    requesterEmailId,
    requesterName,
    pageNo,
    filters = [],
    sortBy = "none",
    forceSend,
    result,
    questionsSummary,
    interviewScheduledTime,
    interviewExpiryTime,
    recruiterTimeZone,
    replyTo,
    view,
}) {
    return {
        type: UPLOAD_INTERVIEW_DATA_RESULT,
        env,
        companyId,
        roleId,
        candidateEmailId,
        candidateName,
        interviewId,
        testId,
        informCandidate,
        candidatePhoneNumber,
        limit,
        pageNo,
        filters,
        requesterEmailId,
        requesterName,
        sortBy,
        result,
        forceSend,
        questionsSummary,
        interviewScheduledTime,
        interviewExpiryTime,
        recruiterTimeZone,
        replyTo,
        view,
        testName,
    };
}

export function createPair({
    env,
    companyId,
    candidateName,
    candidateEmailId,
    candidatePhoneNumber,
    requesterEmailId,
    requesterName,
    instructions,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
}) {
    return {
        type: CREATE_PAIR,
        companyId,
        candidateName,
        candidateEmailId,
        candidatePhoneNumber,
        requesterEmailId,
        requesterName,
        instructions,
        limit,
        pageNo,
        filters,
        sortBy,
    };
}

export function uploadInterviewData({
    env,
    companyId,
    roleId,
    candidateEmailId,
    questionsSummary,
    testId,
    testName,
    candidateName,
    candidatePhoneNumber,
    interviewId,
    informCandidate,
    limit,
    requesterEmailId,
    requesterName,
    pageNo,
    filters = [],
    sortBy = "none",
    forceSend,
    interviewScheduledTime,
    interviewExpiryTime,
    recruiterTimeZone,
    replyTo,
    view,
}) {
    return {
        type: UPLOAD_INTERVIEW_DATA,
        env,
        companyId,
        roleId,
        candidateEmailId,
        candidateName,
        interviewId,
        testId,
        testName,
        informCandidate,
        candidatePhoneNumber,
        limit,
        pageNo,
        filters,
        requesterEmailId,
        requesterName,
        sortBy,
        questionsSummary,
        forceSend,
        interviewScheduledTime,
        interviewExpiryTime,
        recruiterTimeZone,
        replyTo,
        view,
    };
}

export function uploadInterviewDataSuccess({
    status,
    companyId,
    candidate,
    view,
}) {
    return {
        type: UPLOAD_INTERVIEW_DATA_SUCCESS,
        status,
        companyId,
        candidate,
        view,
    };
}

export function addRole({ env, companyId, displayName }) {
    return {
        type: EDIT_ROLES,
        displayName,
        companyId,
        env,
    };
}

export function completelyDeleteUser({ env, companyId, emailId }) {
    return {
        type: COMPLETELY_DELETE_USER,
        emailId,
        companyId,
        env,
    };
}

export function deleteUser({ env, companyId, email }) {
    return {
        type: DELETE_USER,
        email,
        companyId,
        env,
    };
}

export function deactivateUser({ env, companyId, email, deactivate }) {
    return {
        type: DEACTIVATE_USER,
        email,
        companyId,
        deactivate,
        env,
    };
}

export function changePermissions({ env, email, companyId, permissions }) {
    return {
        type: CHANGE_PERMISSION,
        email,
        companyId,
        env,
        permissions,
    };
}

export function resendTeamInvite({ env, email, companyId }) {
    return {
        type: RESEND_TEAM_INVITE,
        email,
        env,
        companyId,
    };
}

export function editTeamMember({
    email,
    tests,
    companyId,
    permission,
    team,
    env,
}) {
    return {
        type: EDIT_TEAM_MEMBER,
        email,
        tests,
        companyId,
        permission,
        team,
        env,
    };
}

export function addUser({
    env,
    companyId,
    permissions,
    userEmailId,
    name,
    role,
    invitingName,
    invitingEmailId,
    teamSize,
    sampleTests,
    restrictions,
}) {
    return {
        type: EDIT_USERS,
        userEmailId,
        companyId,
        name,
        role,
        env,
        invitingName,
        invitingEmailId,
        permissions,
        teamSize,
        sampleTests,
        restrictions,
    };
}

export function updateRole({ env, roleId, companyId, dtreeKey, displayName }) {
    return {
        type: EDIT_ROLES,
        roleId,
        companyId,
        dtreeKey,
        displayName,
        env,
    };
}

export function changeCurrentMode({ mode, currentModeMetadata }) {
    return {
        type: CHANGE_CURRENT_MODE,
        mode,
        currentModeMetadata,
    };
}

export function updateFAQs({ companyId, faqs, enable }) {
    return {
        type: UPDATE_FAQS,
        companyId,
        faqs,
        enable,
    };
}

export function updateFAQsStatus({ status, companyId, faqs, enable }) {
    return {
        type: UPDATE_FAQS_STATUS,
        companyId,
        faqs,
        status,
        enable,
    };
}

export function createNewTest({ questions, name, env, userId }) {
    return {
        type: CREATE_NEW_TEST,
        questions,
        name,
        env,
        userId,
    };
}

export function changeNewTestNameStatus({ status }) {
    return {
        type: CHANGE_NEW_TEST_NAME_STATUS,
        status,
    };
}

export function updateQuestionField({ questionId, isMetadata, field, value }) {
    return {
        type: UPDATE_QUESTION_FIELD,
        questionId,
        isMetadata,
        field,
        value,
    };
}

export function updateQuestionFieldStatus({
    status,
    questionId,
    isMetadata,
    field,
    value,
}) {
    return {
        type: UPDATE_QUESTION_FIELD_STATUS,
        status,
        questionId,
        isMetadata,
        field,
        value,
    };
}

export function getUserDetails({ env, userId }) {
    return {
        type: GET_USER_DETAILS,
        env,
        userId,
    };
}

export function getUserDetailsSuccess({ env, userId, data }) {
    return {
        type: GET_USER_DETAILS_SUCCESS,
        env,
        userId,
        data,
    };
}

export function setCurrentUserId({ userId }) {
    return {
        type: SET_CURRENT_USER_ID,
        userId,
    };
}

export function getAllCategories({ isAdmin }) {
    return {
        type: GET_ALL_CATEGORIES,
        isAdmin,
    };
}

export function getAllCategoriesSuccess({ categories }) {
    return {
        type: GET_ALL_CATEGORIES_SUCCESS,
        categories,
    };
}

export function getQuestionsData({
    env,
    companyId,
    isAdmin,
    pageNo,
    limit = 50,
    filters,
}) {
    return {
        type: GET_QUESTIONS_DATA,
        env,
        companyId,
        isAdmin,
        pageNo,
        limit,
        filters,
    };
}

export function getQuestionsSuccess({ env, companyId, data, pageNo }) {
    return {
        type: GET_QUESTIONS_SUCCESS,
        env,
        companyId,
        data,
        pageNo,
    };
}

export function getQuestionsDataTest({ env, companyId, testId, isAdmin }) {
    return {
        type: GET_QUESTIONS_DATA_TEST,
        env,
        companyId,
        testId,
        isAdmin,
    };
}

export function getQuestionsTestSuccess({ env, companyId, testId, questions }) {
    return {
        type: GET_QUESTIONS_DATA_TEST_SUCCESS,
        env,
        companyId,
        testId,
        questions,
    };
}

export function updateTestBuilderQuestions({ questions }) {
    return {
        type: UPDATE_TEST_BUILDER_QUESTIONS,
        questions,
    };
}

export function updateTestBuilderTest({
    displayName,
    dtreeKey,
    testId,
    questions,
    roleId,
    threshold,
    timeboxStyle,
    maxTimeInMinutes,
    timeboxed,
    subscribers,
    proctoringMode,
    requestId,
}) {
    return {
        type: UPDATE_TEST_BUILDER_TEST,
        displayName,
        dtreeKey,
        testId,
        questions,
        roleId,
        threshold,
        maxTimeInMinutes,
        timeboxed,
        subscribers,
        timeboxStyle,
        proctoringMode,
        requestId,
    };
}

export function resetTest() {
    return {
        type: RESET_TEST,
    };
}

export function addQuestionsToTest({
    env,
    via,
    companyId,
    testId,
    questions,
    mode,
    alertDiff,
}) {
    return {
        type: ADD_QUESTIONS_TO_TEST,
        env,
        via,
        companyId,
        testId,
        questions,
        mode,
        alertDiff,
    };
}

export function addQuestionsToTestResult({
    success,
    error,
    newQuestions,
    companyId,
    testId,
    updatedTest,
}) {
    return {
        type: ADD_QUESTIONS_TO_TEST_RESULT,
        success,
        error,
        newQuestions,
        companyId,
        testId,
        updatedTest,
    };
}

export function customizeQuestionsToTestResult({
    companyId,
    testId,
    success,
    error,
    updatedTest,
}) {
    return {
        type: CUSTOMIZE_QUESTIONS_TO_TEST_RESULT,
        companyId,
        testId,
        success,
        error,
        updatedTest,
    };
}

export function verifyQuestion({ questionId, field, value, companyId }) {
    return {
        type: VERIFY_QUESTION,
        questionId,
        field,
        value,
        companyId,
    };
}

export function verifyQuestionSuccess({ newQuestions }) {
    return {
        type: VERIFY_QUESTION_SUCCESS,
        newQuestions,
    };
}

export function sortQuestionsDnD({
    env,
    companyId,
    testId,
    questions,
    dtreesDetails,
}) {
    return {
        type: SORT_QUESTIONS_DND,
        env,
        companyId,
        testId,
        questions,
        dtreesDetails,
    };
}

export function sortQuestionsDnDResult({ success, error, companyId, testId }) {
    return {
        type: SORT_QUESTIONS_DND_RESULT,
        success,
        error,
        companyId,
        testId,
    };
}

export function fetchRTGData() {
    return {
        type: FETCH_RTG_DATA,
    };
}

export function fetchRtgDataSuccess({ data }) {
    return {
        type: FETCH_RTG_DATA_SUCCESS,
        data,
    };
}

export function fetchSaasPlans({ planId, pairPlanId }) {
    return { type: FETCH_SAAS_PLANS, planId, pairPlanId };
}

export function fetchSaasPlansSuccess({ data }) {
    return { type: FETCH_SAAS_PLANS_SUCCESS, data };
}

export function deleteQuestionFromTest({ questionId, companyId, testId }) {
    return {
        type: DELETE_QUESTION_TEST,
        questionId,
        companyId,
        testId,
    };
}

export function deleteQuestionFromTestResult({
    success,
    error,
    test,
    companyId,
    testId,
}) {
    return {
        type: DELETE_QUESTION_TEST_RESULT,
        success,
        error,
        test,
        companyId,
        testId,
    };
}

export function deletePreviewQuestionFromTest({
    questions,
    dtreesDetails,

    testId,
}) {
    return {
        type: DELETE_PREVIEW_QUESTION_TEST,
        questions,
        dtreesDetails,

        testId,
    };
}

export function createReadyTest({
    env,
    requesterName,
    companyId,
    testId,
    requesterEmailId,
    skills,
    coding,
}) {
    return {
        type: CREATE_READY_TEST,
        env,
        companyId,
        testId,
        skills,
        coding,
        requesterEmailId,
        requesterName,
    };
}

export function publishUpdateTest({
    env,
    questions,
    testId,
    roleId,
    displayName,
    companyId,
    dtreeKey,
    userId,
    threshold,
    maxTimeInMinutes,
    dtreesDetails,
    subscribers,
    timeboxed,
    proctoringMode,
    requestId,
}) {
    sendToast(
        testId
            ? `Updating ${displayName}...`
            : `Creating new test (${displayName})...`,
        PUBLISH_UPDATE_TEST
    );
    return {
        type: PUBLISH_UPDATE_TEST,
        env,
        questions,
        testId,
        roleId,
        displayName,
        companyId,
        dtreeKey,
        userId,
        dtreesDetails,
        threshold,
        maxTimeInMinutes,
        subscribers,
        proctoringMode,
        timeboxed,
        requestId,
    };
}

export function cloneTest({ env, companyId, testId }) {
    return {
        type: CLONE_TEST,
        env,
        companyId,
        testId,
    };
}

export function cloneTestStatus({ status }) {
    return {
        type: CLONE_TEST_STATUS,
        status,
    };
}

export function createBlankTest({ env, companyId, displayName }) {
    return {
        type: CREATE_BLANK_TEST,
        env,
        companyId,
        displayName,
    };
}

export function createBlankTestStatus({ status }) {
    return {
        type: CREATE_BLANK_TEST_STATUS,
        status,
    };
}

export function previewAssessment({
    env,
    testId,
    companyId,
    requesterEmailId,
    requesterName,
}) {
    return {
        type: PREVIEW_ASSESSMENT,
        env,
        testId,
        companyId,
        requesterEmailId,
        requesterName,
    };
}

export function previewAssessmentSuccess({
    testId,
    status,
    newPreviewTest,
    questionsDetails,
}) {
    return {
        type: PREVIEW_ASSESSMENT_SUCCESS,
        testId,
        status,
        newPreviewTest,
        questionsDetails,
    };
}

export function getPreviewTest({
    testId,
    mcqCategories,
    codingCategories,
    companyId,
    env,
}) {
    return {
        type: GET_PREVIEW_TEST,
        testId,
        mcqCategories,
        codingCategories,
        companyId,
        env,
    };
}

export function getPreviewTestSuccess({
    testId,
    status,
    previewTest,
    companyId,
    questionsDetails,
}) {
    return {
        type: GET_PREVIEW_TEST_SUCCESS,
        testId,
        status,
        previewTest,
        companyId,
        questionsDetails,
    };
}

export function fetchAddress({ companyId }) {
    return {
        type: FETCH_ADDRESS,
        companyId,
    };
}

export function fetchAddressSuccess({ status, companyId, address }) {
    return {
        type: FETCH_ADDRESS_SUCCESS,
        companyId,
        address,
    };
}

export function fetchLiveUpdate({ }) {
    return {
        type: FETCH_LIVE_UPDATES,
    };
}

export function fetchLiveUpdateSuccess({ status, liveUpdates }) {
    return {
        type: FETCH_LIVE_UPDATES_SUCCESS,
        status,
        liveUpdates,
    };
}

export function fetchSubmissions({ limit, page, companyId }) {
    return {
        type: FETCH_SUBMISSIONS,
        limit,
        page,
        companyId,
    };
}

export function fetchSubmissionsSuccess({
    status,
    submissions,
    submissionCount,
}) {
    return {
        type: FETCH_SUBMISSIONS_SUCCESS,
        status,
        submissions,
        submissionCount,
    };
}

export function fetchProblems({ companyId }) {
    return {
        type: FETCH_PROBLEMS,
        companyId,
    };
}

export function fetchProblemsSuccess({ status, problems }) {
    return {
        type: FETCH_PROBLEMS_SUCCESS,
        status,
        problems,
    };
}

export function saveProblem({
    id,
    name,
    code,
    description,
    testcases,
    companyId,
}) {
    return {
        type: SAVE_PROBLEM,
        id,
        name,
        code,
        description,
        testcases,
        companyId,
    };
}

export function saveProblemSuccess({ success }) {
    return {
        type: SAVE_PROBLEM_SUCCESS,
        success,
    };
}

export function saveProblemFailure({ success, error }) {
    return {
        type: SAVE_PROBLEM_FAILURE,
        success,
        error,
    };
}

export function clearSaveProblemStatus({ }) {
    return {
        type: CLEAR_SAVED_PROBLEM,
    };
}

export function setEditProblem({ problem }) {
    return {
        type: SET_EDIT_PROBLEM,
        problem,
    };
}

export function setEditCustomQuestion({ question }) {
    return {
        type: SET_EDIT_CUSTOM_QUESTION,
        question,
    };
}

export function fetchNotificationFeed({
    companyId,
    nextPageNotificationIndex,
}) {
    return {
        type: FETCH_NOTIFICATION_FEED,
        companyId,
        nextPageNotificationIndex,
    };
}

export function fetchNotificationFeedStatus({ companyId, notificationFeed }) {
    return {
        type: FETCH_NOTIFICATION_FEED_STATUS,
        companyId,
        notificationFeed,
    };
}

export function fetchAdaAIContacts({ companyId }) {
    return {
        type: FETCH_ADA_AI_CONTACTS,
        companyId,
    };
}

export function fetchAdaAIContactsStatus({ status, contacts }) {
    return {
        type: FETCH_ADA_AI_CONTACTS_STATUS,
        status,
        contacts,
    };
}

export function fetchAdaAIContactDocuments({ companyId, contactId }) {
    return {
        type: FETCH_ADA_AI_CONTACT_DOCUMENTS,
        companyId,
        contactId,
    };
}

export function fetchAdaAIContactDocumentsStatus({
    status,
    documents,
    contactId,
}) {
    return {
        type: FETCH_ADA_AI_CONTACT_DOCUMENTS_STATUS,
        status,
        contactId,
        documents,
    };
}

export function fetchAdaAIContactSessions({
    companyId,
    userId,
    contactId,
    clearSession,
    isMultiContact,
}) {
    return {
        type: FETCH_ADA_AI_CONTACT_SESSIONS,
        companyId,
        userId,
        contactId,
        clearSession,
        isMultiContact,
    };
}

export function fetchAdaAIContactSessionsStatus({
    status,
    contactId,
    session,
    isMultiContact,
    clearSession,
}) {
    return {
        type: FETCH_ADA_AI_CONTACT_SESSIONS_STATUS,
        status,
        contactId,
        session,
        isMultiContact,
        clearSession,
    };
}

export function createAdaAIQuery({ sessionId, query, queryType }) {
    return {
        type: CREATE_ADA_AI_QUERY,
        sessionId,
        query,
        queryType,
    };
}

export function createAdaAIQueryStatus({
    sessionId,
    status,
    query,
    queryId,
    response,
    currentLog,
    queryType,
}) {
    return {
        type: CREATE_ADA_AI_QUERY_STATUS,
        status,
        query,
        sessionId,
        queryId,
        response,
        currentLog,
        queryType,
    };
}

export function upsertDocuments({ companyId, documentUrls, userId }) {
    return {
        type: UPSERT_DOCUMENTS,
        companyId,
        documentUrls,
        userId,
    };
}

export function upsertDocumentsStatus({
    companyId,
    status,
    documents,
    sessionId,
    readableStatus,
    processingStatus,
}) {
    return {
        type: UPSERT_DOCUMENTS_STATUS,
        companyId,
        status,
        documents,
        sessionId,
        readableStatus,
        processingStatus,
    };
}

export function addOrUpdateListmonk({ name, email, attributes }) {
    return {
        type: ADD_OR_UPDATE_LISTMONK,
        name,
        email,
        attributes,
    };
}

export function getBlockedEmails() {
    return {
        type: GET_BLOCKED_EMAILS,
    };
}

export function getBlockedEmailsSuccess({ blockedEmails }) {
    return {
        type: GET_BLOCKED_EMAILS_SUCCESS,
        blockedEmails,
    };
}

export function deleteBlockedEmail({ email }) {
    return {
        type: DELETE_BLOCKED_EMAIL,
        email,
    };
}

export function deleteBlockedEmailSuccess({ email }) {
    return {
        type: DELETE_BLOCKED_EMAIL_SUCCESS,
        email,
    };
}

export function sendCustomTestReadyEmail({
    name,
    email,
    comment,
    testName,
    testLink,
}) {
    return {
        type: SEND_CUSTOM_TEST_READY_EMAIL,
        name,
        email,
        comment,
        testName,
        testLink,
    };
}

export function extendInterviewDeadline({
    interviewId,
    companyId,
    updatedDeadline,
}) {
    return {
        type: EXTEND_INTERVIEW_DEADLINE,
        interviewId,
        companyId,
        updatedDeadline,
    };
}

export function extendInterviewDeadlineSuccess({
    interviewId,
    updatedDeadline,
}) {
    return {
        type: EXTEND_INTERVIEW_DEADLINE_SUCCESS,
        interviewId,
        updatedDeadline,
    };
}

export function extendInterviewDeadlineFailure({ error }) {
    return {
        type: EXTEND_INTERVIEW_DEADLINE_FAILURE,
        error,
    };
}
